import type { DesignSrcFile } from '@knapsack/types';
import { useAppClientDataSelector } from '@/core';
import { appApiGql } from '@/services/app-api-client';
import { createReactQueryFns } from '@/services/react-query';
import { knapsackGlobal } from '@/global';
import {
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { imageLoader } from './design-src-image-data-loader';

const {
  useFnSuspense: useDesignSrcFileContentsSuspense,
  get: getDesignSrcFileContents,
} = createReactQueryFns({
  fn: appApiGql.getDesignSrcFileContents,
});

export function useDesignSrcFiles() {
  return useAppClientDataSelector(
    ({ db }) => db.settings.designSrcs?.files || [],
    { isEqualityDeep: true },
  );
}

/**
 * Fetch the version info for a design src file from the app client data
 */
export function useDesignSrcFileVersionInfo(
  fileId: string | { fileId: string },
) {
  const id = typeof fileId === 'string' ? fileId : fileId.fileId;
  return useAppClientDataSelector(
    ({ db }) =>
      db.settings.designSrcs?.files?.find((file) => file.fileId === id),
    {
      dependencies: [id],
    },
  );
}

/** Uses Suspense */
export function useDesignSrcFile(fileId: string | { fileId: string }) {
  const id = typeof fileId === 'string' ? fileId : fileId?.fileId;
  if (!id) {
    throw new Error('fileId is required');
  }
  const { fileId: theFileId, version } = useDesignSrcFileVersionInfo(id);
  const { designSrcFile } = useDesignSrcFileContentsSuspense({
    fileId: theFileId,
    version,
  });
  return designSrcFile;
}

/**
 * Attempts to prime the design src file cache
 * returns true if files are found and false if not
 */
export function primeDesignSrcFileCache(): boolean {
  const files = knapsackGlobal.getState().db.settings.designSrcs?.files;
  if (!files) {
    return false;
  }
  files.forEach((file) => {
    getDesignSrcFileContents({
      fileId: file.fileId,
      version: file.version,
    });
  });
  return true;
}

export function useDesignSrcFileInfo(
  fileId: string | { fileId: string },
  version: string,
) {
  const id = typeof fileId === 'string' ? fileId : fileId?.fileId;
  if (!id) {
    throw new Error('fileId is required');
  }
  const queryResults = useQuery({
    queryKey: ['getFigmaFileMetadata', id, version],
    queryFn: () =>
      appApiGql.getFigmaFileMetadata({
        fileId: id,
        version,
      }),
  });
  const { data, error, isLoading, refetch, isSuccess } = queryResults;
  return {
    designSrcFile: data?.figmaFileMetadata,
    error,
    isLoading,
    refetch,
    isSuccess,
  };
}

const {
  useFnSuspense: useDesignSrcComponentSuspense,
  get: getDesignSrcComponent,
} = createReactQueryFns({
  fn: appApiGql.getDesignSrcComponent,
});

/** Uses Suspense */
export function useDesignSrcComponent({
  componentId,
  fileId,
}: {
  fileId: string;
  componentId: string;
}) {
  const file = useDesignSrcFileVersionInfo(fileId);

  // In case the file is deleted for whatever reason
  if (!file) {
    console.warn(`Could not load file for design source file ID: ${fileId}`);
  }

  const { designSrcFile } = useDesignSrcComponentSuspense({
    componentId,
    fileId: file ? file.fileId : '',
    version: file ? file?.version : '',
  });
  return designSrcFile?.version?.component;
}

/** Does not use Suspense */
export function useDesignSrcComponentQuery({
  componentId,
  fileId,
}: {
  fileId: string;
  componentId: string;
}) {
  const file = useDesignSrcFileVersionInfo(fileId);
  const queryResults = useQuery({
    queryKey: ['getDesignSrcComponent', componentId, file.fileId, file.version],
    queryFn: () =>
      appApiGql.getDesignSrcComponent({
        componentId,
        fileId: file.fileId,
        version: file.version,
      }),
  });

  const { data, error, isLoading, refetch, isSuccess } = queryResults;
  return {
    data,
    error,
    isLoading,
    refetch,
    isSuccess,
  };
}

const createGetDesignSrcFilesComponents = ({
  files,
}: {
  files: DesignSrcFile[];
}) =>
  queryOptions({
    queryKey: ['getDesignSrcComponents', files],
    queryFn: () =>
      Promise.all(
        files.map(async (file) => {
          const { designSrcFile } = await appApiGql.getDesignSrcComponents({
            fileId: file.fileId,
            version: file.version,
          });
          return {
            file,
            components: designSrcFile?.version?.components || [],
          };
        }),
      ),
  });

/**
 * Uses Suspense
 * @see {@link useDesignSrcComponentsInFiles}
 */
export function useDesignSrcComponentsInFilesSuspense() {
  const files = useDesignSrcFiles();
  const { data } = useSuspenseQuery(
    createGetDesignSrcFilesComponents({ files }),
  );
  return data || [];
}

/**
 * Does not use Suspense
 * @see {@link useDesignSrcComponentsInFilesSuspense}
 */
export function useDesignSrcComponentsInFiles() {
  const files = useDesignSrcFiles();
  const { data } = useQuery(createGetDesignSrcFilesComponents({ files }));
  return data || [];
}

export function primeDesignSrcComponentCache({
  componentId,
  fileId,
}: {
  fileId: string;
  componentId: string;
}) {
  const version = knapsackGlobal
    .getState()
    .db.settings.designSrcs?.files?.find(
      (file) => file.fileId === fileId,
    )?.version;
  if (!version) return;
  getDesignSrcComponent({
    componentId,
    fileId,
    version,
  });
}

/** Does not use   Suspense */
export function useDesignSrcNodeImage({
  fileId,
  nodeId,
}: {
  nodeId: string;
  fileId: string;
}) {
  const file = useDesignSrcFileVersionInfo(fileId);
  return useQuery({
    queryKey: ['getDesignSrcNodeImage', file?.version, file?.fileId, nodeId],
    queryFn: async () => {
      return imageLoader.load({
        fileId: file?.fileId || '',
        nodeId,
        version: file?.version || '',
      });
    },
    retry: (failureCount, error) => {
      if (error.message.includes('Render timeout')) {
        return false;
      }
      if (failureCount >= 3) return false;
      return true;
    },
  });
}

export function useLatestDesignSrcFileVersion(fileId: string) {
  const queryInfo = useQuery({
    queryKey: ['getLatestDesignSrcFileVersion', fileId],
    queryFn: () =>
      appApiGql.getDesignSrcFileLatestVersion({
        fileId,
      }),
  });

  const latestVersionValue = queryInfo.data?.latestDesignSrcFileVersion.version;

  return {
    latestVersionValue,
    isLoading: queryInfo.isLoading,
    error: queryInfo.error,
  };
}

export function useFigmaUserInfo(userId: string) {
  const { data, error, isLoading, refetch, isSuccess } = useSuspenseQuery({
    queryKey: ['getFigmaUserInfo', userId],
    queryFn: () => appApiGql.getFigmaUserInfo({ userId }),
  });

  const { figmaUserInfo } = data;

  return {
    figmaUserInfo,
    error,
    isLoading,
    isSuccess,
    refetch,
  };
}
